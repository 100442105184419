<template>
  <div id="welcome-page" class="page-container flex-center flex-column">
    <h1 class="text-center" v-text="t('welcomeTo')" />
    <div class="modal-card modal-card-visible flex-center text-center">
      <DataForm @continue="onContinue" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import DataForm from '@/components/DataForm.vue'
import { useDataStore } from '@/stores/data'
import { useLocalStorageStore } from '@/stores/local-storage'
import { useMqttStore } from '@/stores/mqtt'

import { routeNames } from '@/router'
import { UserMessagePayload } from 'types/mqtt-messages'

export default defineComponent({
  components: {
    DataForm,
  },

  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const localStorageStore = useLocalStorageStore()
    const dataStore = useDataStore()

    const userData = localStorageStore.getUserData()
    if (dataStore.museumType === 'location') {
    // check if data was already stored, if so just send them to home
      if (userData?.tag) {
      // update tag if provided
        const queryTag = typeof route.query.tag === 'string' ? route.query.tag : null
        if (queryTag) {
          localStorageStore.setUserData({ ...userData, tag: queryTag })
        }
        void router.push({ name: routeNames.HOME })
      }
    }
    const mqttStore = useMqttStore()
    async function onContinue (): Promise<void> {
      await router.push({ name: routeNames.HOME })
      if (dataStore.museumType === 'location') {
        const userData = localStorageStore.getUserData()
        if (!userData) return
        mqttStore.publish<UserMessagePayload>('user', {
          tag: userData.tag,
          languageCode: userData.language,
        }, {
          qos: 2,
        })
      }
    }

    return {
      t,
      onContinue,
    }
  },
})
</script>

<style lang="scss" scoped>
#welcome-page {
  height: 100vh;
}

h1 {
  font-family: 'JW Knoll Display';
  font-size: 26px;
  font-weight: bold;
  margin: 45px 0 60px;
}
</style>
