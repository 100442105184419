<template>
  <div v-if="exhibit" class="page-container flex-column">
    <ExhibitBanner :exhibit="exhibit" />
    <ExperienceList :experiences="experiences" />
    <ExperiencePlayer
      :open="!!activeExperience"
      :has-next="hasNextExperience"
      @next="onNext"
      @close="onClose"
    />
    <SouvenirItem v-if="exhibit.souvenirId" :exhibit="exhibit" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import ExhibitBanner from '@/components/ExhibitBanner.vue'
import ExperienceList from '@/components/ExperienceList.vue'
import ExperiencePlayer from '@/components/ExperiencePlayer.vue'
import SouvenirItem from '@/components/SouvenirItem.vue'
import { useExperienceStore } from '@/stores/experience'
import { useRouterStore } from '@/stores/router'

export default defineComponent({
  components: {
    ExhibitBanner,
    ExperienceList,
    ExperiencePlayer,
    SouvenirItem,
  },

  setup () {
    const routerStore = useRouterStore()
    const exhibit = computed(() => routerStore.exhibit)
    const experiences = computed(() => exhibit.value?.experiences ?? [])

    const experienceStore = useExperienceStore()
    const activeExperience = computed(() => {
      return experienceStore.activeExperience
    })
    const nextExperience = computed(() => {
      if (!activeExperience.value) return null
      const idx = experiences.value.findIndex(e => experienceStore.getId(e) === activeExperience.value?.id)
      if (idx === -1) return null
      return experiences.value[idx + 1] ?? null
    })
    const hasNextExperience = computed(() => !!nextExperience.value)
    function onNext (): void {
      if (!nextExperience.value) return
      experienceStore.activate(nextExperience.value)
      void experienceStore.playPause()
    }

    function onClose (): void {
      experienceStore.deactivate()
    }

    return {
      exhibit,
      experiences,
      activeExperience,

      hasNextExperience,
      onNext,

      onClose,
    }
  },
})
</script>
