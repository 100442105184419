<template>
  <div v-if="display" class="page-container flex-column">
    <DisplayConnect v-model="connected" :exhibit="display" />

    <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
    <div v-if="demoStore.isDemo" class="demo-section flex-center flex-column">
      <p class="text-center">
        (Demo media below)
      </p>
      <button
        v-for="experience of experiences"
        :key="experience.id"
        class="btn"
        @click="selectDemoExperience(experience)"
        v-text="experience.name || '[???]'"
      />
      <button class="btn stop-btn" @click="selectDemoExperience(null)">
        Stop All
      </button>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, ref } from 'vue'

import DisplayConnect from '@/components/DisplayConnect.vue'
import { useDemoStore } from '@/stores/demo'
import { useMqttStore } from '@/stores/mqtt'
import { useRouterStore } from '@/stores/router'

import type { Experience } from 'types/data'
import type { DisplayMessagePayload } from 'types/mqtt-messages'

export default defineComponent({
  components: {
    DisplayConnect,
  },

  setup () {
    const routerStore = useRouterStore()
    const display = computed(() => routerStore.exhibit)
    const experiences = computed(() => display.value?.experiences ?? [])

    const demoStore = useDemoStore()
    const mqttStore = useMqttStore()
    const demoInterval = ref(0)
    function selectDemoExperience (experience: Experience | null): void {
      window.clearInterval(demoInterval.value)
      const topic = `display/${display.value?.id}`
      if (!experience) {
        mqttStore.publish<DisplayMessagePayload>(topic, {
          currentTime: 0,
          experienceId: 0,
        })
        return
      }
      let currentTime = 0
      const length = experience.media?.length ? experience.media.length : 30
      demoInterval.value = window.setInterval(() => {
        if (currentTime >= length) {
          window.clearInterval(demoInterval.value)
          mqttStore.publish<DisplayMessagePayload>(topic, {
            currentTime: 0,
            experienceId: 0,
          })
        } else {
          mqttStore.publish<DisplayMessagePayload>(topic, {
            currentTime,
            experienceId: experience.id,
          })
          currentTime += 0.5
        }
      }, 500)
    }
    onUnmounted(() => {
      window.clearInterval(demoInterval.value)
    })
    const connected = ref(false)

    return {
      display,
      experiences,

      demoStore,
      selectDemoExperience,

      connected,
    }
  },
})
</script>

<style lang="scss" scoped>
.demo-section {
  margin-top: 40px;
  button {
    margin-top: 8px;
    &.stop-btn {
      background-color: var(--error);
    }
  }
}
</style>
