import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDataStore } from '@/stores/data'

import type { TourCard, Exhibit } from 'types/data'
import type { ComputedRef, DeepReadonly } from 'vue'

interface ExhibitStoreReturnValue {
  exhibits: ComputedRef<DeepReadonly<Exhibit[]>>
  getExhibitById: (id: string | number | null) => DeepReadonly<Exhibit> | undefined
  cards: ComputedRef<DeepReadonly<TourCard[]>>
  getCardById: (id: string | number | null) => DeepReadonly<TourCard> | undefined
}

export const useExhibitStore = defineStore('exhibit', (): ExhibitStoreReturnValue => {
  const { locale } = useI18n()
  const dataStore = useDataStore()

  const exhibits: ExhibitStoreReturnValue['exhibits'] = computed(() => {
    const languageExhibit = dataStore.languageExhibits.find((l) => l.symbol === locale.value)
    if (!languageExhibit?.exhibits) return []
    return languageExhibit.exhibits
  })

  const getExhibitById: ExhibitStoreReturnValue['getExhibitById'] = (id) => {
    if (!id) return
    return exhibits.value.find((e) => e.id === +id)
  }

  const cards: ExhibitStoreReturnValue['cards'] = computed(() => {
    const languageTour = dataStore.languageTourCatalogs.find((l) => l.symbol === locale.value)
    if (!languageTour?.cards) return []
    return languageTour.cards
  })

  const getCardById: ExhibitStoreReturnValue['getCardById'] = (id) => {
    if (!id) return
    return cards.value.find((e) => e.tourId === +id)
  }

  return {
    exhibits,
    getExhibitById,
    cards,
    getCardById,
  }
})
