<template>
  <Teleport to="body">
    <div
      class="modal-overlay"
      :class="{ 'modal-overlay-visible': visible }"
      @click="toggleVisible"
    />
    <div
      ref="cardRef"
      class="modal-card flex-center text-center"
      :class="{ 'modal-card-visible': visible }"
    >
      <DataForm @continue="onContinue" />
      <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
      <div
        v-if="demoStore.isDemo"
        class="demo-section demo-settings flex-center flex-column"
      >
        <p class="text-center">
          (Demo tools below)
        </p>
        <button class="btn" @click="onHome">
          Home
        </button>
        <button class="btn" @click="onNetwork">
          Network
        </button>
        <button class="btn" @click="onClearPlayHistory">
          Clear Play History
        </button>
        <button class="btn" @click="onClearNotifications">
          Clear Notifications
        </button>
        <button class="btn" @click="onClearSouvenirs">
          Clear Souvenirs
        </button>
      </div>
      <p class="version-number" v-text="versionNumber" />
      <!-- eslint-enable-->
    </div>
  </Teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import DataForm from './DataForm.vue'
import { useDemoStore } from '@/stores/demo'
import { useExperienceStore } from '@/stores/experience'
import { useLocalStorageStore } from '@/stores/local-storage'
import { useModalStore } from '@/stores/modal'
import { useMqttStore } from '@/stores/mqtt'

import { routeNames } from '@/router'

import type { LocationMessagePayload } from 'types/mqtt-messages'

export default defineComponent({
  components: {
    DataForm,
  },

  setup () {
    const modalStore = useModalStore()
    const visible = computed(() => modalStore.settings)
    function toggleVisible (): void {
      modalStore.settings = !modalStore.settings
    }
    function onContinue (): void {
      toggleVisible()
    }
    const cardRef = ref<HTMLDivElement | null>(null)
    watch(visible, () => {
      cardRef.value?.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    })

    const envVersion = (import.meta.env.VITE_VERSION || '0')
      .replace('Companion', '')
      .trim()
    const versionNumber = `v${envVersion}`

    // demo
    const demoStore = useDemoStore()
    const router = useRouter()
    const localStorageStore = useLocalStorageStore()
    const mqttStore = useMqttStore()
    const experienceStore = useExperienceStore()
    async function onHome (): Promise<void> {
      await router.push({ name: routeNames.HOME })
      const tag = localStorageStore.getUserData()?.tag
      const message: LocationMessagePayload = {
        exhibitId: 0,
        alertId: -1,
        tourId: -1,
        displayId: -1,
      }
      experienceStore.deactivate()
      mqttStore.publish(`location/${tag}`, message)
      toggleVisible()
    }
    async function onNetwork (): Promise<void> {
      await router.push({ name: routeNames.NETWORK })
      toggleVisible()
    }
    async function onClearPlayHistory (): Promise<void> {
      localStorageStore.playHistory.clear()
      console.log('Cleared play history')
      await onHome()
    }
    async function onClearNotifications (): Promise<void> {
      localStorageStore.notificationHistory.clear()
      console.log('Cleared notifications')
      await onHome()
    }
    async function onClearSouvenirs (): Promise<void> {
      localStorageStore.souvenirHistory.clear()
      console.log('Cleared souvenirs')
      await onHome()
    }

    return {
      visible,
      toggleVisible,
      onContinue,
      cardRef,
      versionNumber,
      // demo
      demoStore,
      onHome,
      onNetwork,
      onClearPlayHistory,
      onClearNotifications,
      onClearSouvenirs,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-card {
  padding: 40px 36px 0;
}
.modal-overlay-visible {
  z-index: var(--z-modal-settings-overlay);
}
.modal-card {
  z-index: var(--z-modal-settings-card);
}
.info-text {
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 16px;
}
.demo-settings {
  margin: 40px 0;
  width: 100%;

  p {
    margin-bottom: 12px;
  }

  button {
    margin: 12px 0;
  }
}
.version-number {
  margin: 36px -36px 8px 0;
  color: var(--gray);
  align-self: flex-end;
}
</style>
