<template>
  <div class="page-container flex-column">
    <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
    <h4>MQTT Connection Status</h4>
    <p v-text="connectionStatus" />
    <h4>Payloads Received</h4>
    <pre v-text="payloadSummary" />
    <!-- eslint-enable -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount } from 'vue'

import { useDemoStore } from '@/stores/demo'
import { useMqttStore } from '@/stores/mqtt'

export default defineComponent({
  setup () {
    const mqttStore = useMqttStore()
    const connectionStatus = computed(() => mqttStore.connectionStatus)

    const demoStore = useDemoStore()
    const payloadSummary = computed(() => {
      return demoStore.payloads.map((p) => {
        const time = p.time.toTimeString().split(' ')[0]
        const payload = JSON.stringify(p.payload)
        return `${time} [${p.source}] ${payload}`
      })
        .reverse()
        .join('\n')
    })
    onBeforeUnmount(() => {
      demoStore.payloads = []
    })

    return {
      connectionStatus,
      payloadSummary,
    }
  },
})
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 6px;
}
pre {
  border: 1px solid var(--black);
  overflow-y: scroll;
  min-height: 60vh;
}
</style>
