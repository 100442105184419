function checkStatus (response: Response): void {
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }
}

export interface ClientIpPayload {
  result: string
  address: string
  hasAccess: boolean
}
export async function getClientIp (): Promise<ClientIpPayload> {
  const response = await fetch('/api/clientip/')
  checkStatus(response)
  return await response.json()
}

export interface BranchDataPayload {
  jsonUrl: string
  displaySyncUrl: string
  isDeployed: boolean
  ipAddress: string
  token: string
}
export async function getBranchData (): Promise<BranchDataPayload> {
  const response = await fetch('/api/branchdata/')
  checkStatus(response)
  return await response.json()
}
