<template>
  <div class="exhibit-banner flex-column">
    <p class="title text-center" v-html="exhibit.name" />
    <template v-if="videoDetails">
      <video
        ref="videoRef"
        data-test="exhibit-video"
        disablePictureInPicture
        loop
        playsinline
        :aria-label="`Video for ${exhibit.name}`"
        :src="videoDetails.src"
        @click="onPlayPause"
      />
      <div
        data-test="exhibit-video-overlay"
        class="tune-in-overlay flex-center"
        @click.stop="onPlayPause"
      >
        <p v-if="videoDetails.playing">
          <span v-text="t('videoPlayingOnWall')" />
        </p>
        <p v-else>
          <FontAwesomeIcon icon="fa-headphones" size="xs" />
          <span v-text="t('tuneIntoVideo')" />
        </p>
      </div>
    </template>
    <img
      v-else-if="imgSrc"
      data-test="exhibit-image"
      :aria-label="`Image for ${exhibit.name}`"
      :src="imgSrc"
    >
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDataStore } from '@/stores/data'
import { useExperienceStore } from '@/stores/experience'

import { checkPlaybackError } from '@/utils/error'

import type { Exhibit } from 'types/data'
import type { DeepReadonly, PropType } from 'vue'

export default defineComponent({
  props: {
    exhibit: { type: Object as PropType<DeepReadonly<Exhibit>>, required: true },
  },

  setup (props) {
    const { t } = useI18n()
    const dataStore = useDataStore()
    const experienceStore = useExperienceStore()

    const videoRef = ref<HTMLVideoElement | null>(null)
    const videoDetails = computed(() => {
      if (!experienceStore.activeExperience) return null
      const { experience, playState, type } = experienceStore.activeExperience
      if (type !== 'video') return null
      return {
        src: dataStore.getMediaSrc(experience.media),
        currentTime: playState.currentTime,
        playing: playState.state === 'playing',
      }
    })
    const videoPlaying = ref(false)
    watch(videoDetails, async (val, prev) => {
      const el = videoRef.value
      if (!el) return
      if (prev?.src !== val?.src) videoPlaying.value = false
      el.onplaying = () => { videoPlaying.value = true }
      el.onpause = () => { videoPlaying.value = false }
      if (!val) {
        el.style.filter = 'brightness(0)'
      } else {
        if (val.playing) {
          el.style.filter = 'brightness(20%)'
          el.volume = 1
          el.muted = false // for Safari
        } else {
          el.style.filter = 'brightness(100%)'
          el.volume = 0
          el.muted = true // for Safari
        }
        if (!videoPlaying.value && val.currentTime !== 0) {
          // const timeDifference = Math.abs(el.currentTime - val.currentTime)
          // if (timeDifference > 3) el.currentTime = val.currentTime
          el.currentTime = val.currentTime
          try {
            await el.play()
          } catch (err) {
            if (checkPlaybackError(err)) return
            console.error(err)
          }
        }
      }
    })

    const imgSrc = computed(() => {
      const experience = experienceStore.activeExperience?.experience
      const image = (experience && 'image' in experience && experience.image) ? experience.image : props.exhibit.image
      return image ? dataStore.getMediaSrc(image) : ''
    })

    function onPlayPause (): void {
      void experienceStore.playPause()
    }

    onBeforeUnmount(() => {
      const el = videoRef.value
      if (el) {
        el.pause()
        el.currentTime = 0
      }
    })

    return {
      t,

      videoDetails,
      videoRef,
      imgSrc,
      onPlayPause,
    }
  },
})
</script>

<style lang="scss" scoped>
.exhibit-banner {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 18px 20px 0;

  .title {
    color: var(--text);
    font-family: 'JW Knoll Display';
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  img,
  video {
    border-radius: 12px;
    width: 100%;
  }
}
.tune-in-overlay {
  background-color: var(--darkgray);
  border-radius: 4px;
  color: var(--white);
  font-family: 'JW Knoll';
  font-size: 18px;
  font-weight: 500;
  height: 45px;
  position: absolute;
  bottom: 18px;
  left: 32px;
  padding: 0 10px;
  p {
    margin: 0;
    svg {
      margin-right: 8px;
    }
  }
}
</style>
