/* istanbul ignore file @preserve */
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  replayIntegration,
} from '@sentry/vue'
import { getActivePinia } from 'pinia'
import { App } from 'vue'
import { Router } from 'vue-router'

import { useLocalStorageStore } from '@/stores/local-storage'
import { useMqttStore } from '@/stores/mqtt'

import { redactEmail } from '@/utils/string'
import { AnyObject } from 'types/utils'

function buildExtra (): AnyObject {
  try {
    const pinia = getActivePinia()
    if (!pinia) return {}

    const localStorageStore = useLocalStorageStore(pinia)
    const mqttStore = useMqttStore(pinia)
    const userData = { ...localStorageStore.getUserData() }
    userData.email = redactEmail(userData.email)
    return {
      userData,
      connectionStatus: mqttStore.connectionStatus,
    }
  } catch (err) {
    // log to avoid retriggering error handler
    console.log(err)
    return {}
  }
}

export function installSentry (app: App, router: Router): void {
  init({
    app,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    dsn: 'https://380e36972b2eb9424d473ff5b7a3731d@o4506518357082112.ingest.sentry.io/4506518360817664',
    integrations: [
      browserTracingIntegration({
        router,
      }),
      replayIntegration({
        maskAllText: false,
        mask: ['[data-sentry-mask]'],
        blockAllMedia: true,
        maxReplayDuration: 1000 * 60 * 2, // 2 minutes
        networkDetailAllowUrls: [
          window.location.origin,
        ],
      }),
      captureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    beforeSend (event) {
      event.extra = buildExtra()
      return event
    },
    denyUrls: [
      // ios safari extension errors
      /^webkit-masked-url:/i,
      // ignore anything to do with devtunnels
      /\.devtunnels\.ms/i,
    ],
    ignoreErrors: [
      // internal edge error
      'logMutedMessage',
      // none of these should be actionable now
      'AbortError',
    ],
  })
}
