<template>
  <div class="display-connect">
    <p class="display-title" v-text="displayTitle" />
    <div class="display-play-status flex-column flex-center">
      <p class="status-header" v-text="t(currentExperience ? 'currentlyPlaying' : 'nothingPlaying')" />
      <p class="status-text" v-html="currentExperience ? currentTitle : t('connectToDisplayToHear')" />
      <div class="status-ring" :class="{ 'status-ring--active': !!currentExperience }" />
    </div>
    <button
      data-test="connect-btn"
      class="connect-btn btn"
      @click="onDisconnect"
      v-text="t('disconnect')"
    />
    <SouvenirItem v-if="exhibit?.souvenirId" :exhibit="exhibit" />
  </div>
</template>

<script lang="ts">
import { DeepReadonly, PropType, computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import SouvenirItem from '@/components/SouvenirItem.vue'
import { useDisplayConnect } from '@/composables/display-connect'

import type { Exhibit } from 'types/data'

export default defineComponent({
  components: {
    SouvenirItem,
  },

  emits: {
    disconnect: (): true => true,
  },

  props: {
    exhibit: { type: [Object, null] as PropType<DeepReadonly<Exhibit> | null>, required: true },
  },

  setup (props, { emit }) {
    const { t } = useI18n()

    const { currentExperience } = useDisplayConnect({
      exhibit: computed(() => props.exhibit),
    })

    const displayTitle = computed(() => {
      return props.exhibit?.name ?? ''
    })

    const currentTitle = computed<string>(() => {
      if (!currentExperience.value) return 'Nothing Playing'
      if (currentExperience.value.description) return currentExperience.value.description
      if (currentExperience.value.name) return currentExperience.value.name
      return 'Unknown Track'
    })

    function onDisconnect (): void {
      emit('disconnect')
    }

    return {
      t,

      displayTitle,

      currentExperience,
      currentTitle,

      onDisconnect,
    }
  },
})
</script>

<style lang="scss" scoped>
.display-connect {
  position: fixed;
  background-color: var(--midgray);
  color: var(--darkgray);
  top: var(--nav-height);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-display-connect);
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.display-title {
  font-size: 16px;
  font-family: 'JW Knoll Display';
  padding: 0 8px;
}

.display-play-status {
  position: relative;
  width: 280px;
  height: 150px;
  .status-header {
    font-size: 30px;
    color: var(--primary);
    margin-bottom: 22px;
    font-family: 'JW Knoll Demi';
  }
  .status-text {
    font-size: 18px;
  }
  .status-ring {
    position: absolute;
    border-radius: 50%;
    border: 20px solid var(--fadedgray);
    width: 200px;
    height: 200px;
    top: -50px;
    z-index: -1;
    opacity: 0.1;
    transition: all 0.5s ease-in-out;
    &.status-ring--active {
      border-color: var(--primary);
      width: 300px;
      height: 300px;
      top: -100px;
    }
  }
}

button {
  width: 330px;
}
</style>
