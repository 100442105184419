import { onUnmounted } from 'vue'

import { useMqttStore } from '@/stores/mqtt'

import type { AnyObject } from 'types/utils'

interface UseMqttSubscriberReturnValue {
  subscribeTopic: <T extends AnyObject>(topic: string, cb: (payload: T) => void | Promise<void>) => () => void
}

export function useMqttSubscriber (): UseMqttSubscriberReturnValue {
  const mqttStore = useMqttStore()
  const subscriptions: Array<() => void> = []

  const subscribeTopic: UseMqttSubscriberReturnValue['subscribeTopic'] = (topic, cb) => {
    const subscription = mqttStore.subscribe(topic, cb)
    subscriptions.push(subscription)
    return subscription
  }

  onUnmounted(() => {
    for (const unsubscribe of subscriptions) {
      unsubscribe()
    }
    subscriptions.splice(0)
  })

  return {
    subscribeTopic,
  }
}
