<template>
  <NavMenu v-if="!routerStore.onSplash" />
  <main class="container">
    <router-view />
    <LocationWatcher v-if="!routerStore.onSplash" />
  </main>
  <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
  <footer
    v-if="showConnectionStatus"
    id="connection-status"
    data-test="connection-status"
    class="text-center"
  >
    Connection Status: {{ mqttStore.connectionStatus }}
  </footer>
  <!-- eslint-enable -->
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import LocationWatcher from '@/components/LocationWatcher.vue'
import NavMenu from '@/components/NavMenu.vue'
import { useDataStore } from '@/stores/data'
import { useDemoStore } from '@/stores/demo'
import { useMqttStore } from '@/stores/mqtt'
import { useRouterStore } from '@/stores/router'

export default defineComponent({
  components: {
    NavMenu,
    LocationWatcher,
  },

  setup () {
    const routerStore = useRouterStore()
    const demoStore = useDemoStore()
    const mqttStore = useMqttStore()
    const dataStore = useDataStore()

    const { t } = useI18n()
    watch((): string => t('branchName'), (val) => {
      document.title = val === 'branchName' ? 'Companion App' : val
    }, { immediate: true })

    const showConnectionStatus = computed(() => {
      return !routerStore.onSplash && demoStore.isDemo && dataStore.museumType === 'location'
    })

    return {
      routerStore,
      mqttStore,
      showConnectionStatus,
    }
  },
})
</script>

<style lang="scss">
$connection-status-height: 30px;

#connection-status {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  height: $connection-status-height;
  background-color: var(--primary);
  color: var(--white);
}
</style>
