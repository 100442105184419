<template>
  <div class="tour-card-list">
    <div
      v-for="card of cards"
      :key="card.id"
      class="tour-card"
      @click="onSelect(card)"
    >
      <div class="tour-card--img" :style="{ backgroundImage: card.image }" />
      <div class="tour-card--info">
        <div>
          <p v-if="card.title" v-text="card.title" />
          <p v-if="card.description" v-text="card.description" />
        </div>
        <div class="tour-card--select">
          <FontAwesomeIcon
            icon="fa-chevron-right"
            size="2x"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { useDataStore } from '@/stores/data'
import { useExhibitStore } from '@/stores/exhibit'

import { routeNames } from '@/router'

interface Card {
  id: number
  title: string
  description: string
  image: string
}

export default defineComponent({
  setup () {
    const exhibitStore = useExhibitStore()
    const dataStore = useDataStore()
    const cards = computed(() => {
      return exhibitStore.cards.reduce<Card[]>((acc, e) => {
        acc.push({
          id: e.tourId,
          title: e.title ?? '',
          description: e.description ?? '',
          image: `url(${dataStore.getMediaSrc(e.tourImage)})`,
        })
        return acc
      }, [])
    })

    const router = useRouter()
    async function onSelect (card: typeof cards['value'][number]): Promise<void> {
      await router.push({ name: routeNames.TOUR, params: { id: card.id } })
    }

    return {
      cards,
      onSelect,
    }
  },
})
</script>

<style lang="scss" scoped>
h1 {
  font-family: 'JW Knoll Display';
  font-size: 26px;
  font-weight: bold;
  margin: 10px 0 20px;
}

.tour-card {
  display: block;
  position: relative;
  margin: 0 8px 12px;
  border: 1px solid var(--primary);
  overflow: hidden;
  text-decoration: none;
}

.tour-card--img {
  width: 100%;
  height: 50vw;
  background-color: var(--text);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tour-card--info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 12px 8px;
  p {
    margin: 0 4px 8px;
    font-size: 14px;
  }
}

.tour-card--select {
  width: 13vw;
  height: 13vw;
  flex: 0 0 13vw;
  margin-left: 4px;
  margin-right: 8px;
  background-color: var(--primary);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
