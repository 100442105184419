import { defineStore } from 'pinia'
import { ComputedRef, Ref, computed, ref } from 'vue'

import { useDataStore } from '@/stores/data'
import { useLocalStorageStore } from '@/stores/local-storage'

import { LocationMessagePayload } from 'types/mqtt-messages'

interface DemoPayload {
  time: Date
  source: string
  payload: LocationMessagePayload
}

const DEMO_TAGS: string[] = [
  // insert any demo tag ids here
  '9938',
]

interface DemoStoreReturnValue {
  payloads: Ref<DemoPayload[]>
  isDemo: ComputedRef<boolean>
}

export const useDemoStore = defineStore('demo', (): DemoStoreReturnValue => {
  const payloads = ref<DemoPayload[]>([])

  const dataStore = useDataStore()
  const localStorageStore = useLocalStorageStore()
  const isDemo = computed(() => {
    if (DEMO_TAGS.includes(localStorageStore.tag)) return true
    return import.meta.env.DEV && !dataStore.settings.previewMode
  })

  return {
    payloads,
    isDemo,
  }
})
