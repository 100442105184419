export function getElementViewLeft (element: HTMLElement): number {
  let actualLeft = element.offsetLeft
  let current = element.offsetParent as HTMLElement | null
  while (current !== null) {
    actualLeft += current.offsetLeft
    current = current.offsetParent as HTMLElement | null
  }
  const elementScrollLeft = document.body.scrollLeft + document.documentElement.scrollLeft
  return actualLeft - elementScrollLeft
}
