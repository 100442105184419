import mqtt from 'mqtt'
import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'

import { useDataStore } from '@/stores/data'

import { debugLog } from '@/utils/error'

import type { MqttClient, OnMessageCallback } from 'mqtt'
import type { AnyObject } from 'types/utils'
import type { ComputedRef } from 'vue'

type MinimumMqttClient = Pick<MqttClient, 'subscribe' | 'prependListener' | 'listeners' | 'unsubscribe' | 'removeListener' | 'publish'>

export type ConnectionString =
  | 'Connecting...'
  | 'Connected'
  | 'Closed'
  | 'Offline'
  | 'Disconnected'
  | 'Reconnecting...'
  | 'Failed'
  | 'Preview Mode'
  | 'Disabled'

interface MqttStoreReturnValue {
  /**
   * The current connection status represented as a {@link ConnectionString}.
   */
  connectionStatus: ComputedRef<ConnectionString>
  /**
   * Subscribes to the topic and registers a callback.
   * The callback is run every time a message is received for that topic until unsubscribed.
   *
   * @returns An unsubscribe function to stop listening for the topic.
   */
  subscribe: <T extends AnyObject>(topic: string, cb: (payload: T) => void | Promise<void>, options?: mqtt.IClientSubscribeOptions) => (() => void)
  /**
   * Publishes a message to the specified topic.
   *
   * See {@link MqttClient.publish publish}.
   */
  publish: <T extends AnyObject>(topic: string, message: T, options?: mqtt.IClientPublishOptions, callback?: mqtt.PacketCallback) => void
  /**
   * Publishes a message to the specified topic without sending it to the mqtt broker.
   */
  internalPublish: <T extends AnyObject>(topic: string, message: T) => void
}

const DEBUG_SCOPE = 'MQTT'

export const useMqttStore = defineStore('mqtt', (): MqttStoreReturnValue => {
  const connectionStatusInternal = ref<ConnectionString>('Connecting...')
  watch(connectionStatusInternal, (newStatus) => {
    debugLog(DEBUG_SCOPE, `Status changed to ${newStatus}`)
  })
  const subscriptionCounts: Record<string, number> = reactive({})

  const dataStore = useDataStore()

  // just use empty methods if traditional museum
  /* istanbul ignore next @preserve */
  if (dataStore.museumType === 'traditional') {
    return {
      connectionStatus: computed(() => 'Disabled'),
      subscribe: () => () => {},
      publish: () => {},
      internalPublish: () => {},
    }
  }

  function createFakeClient (): MinimumMqttClient {
    const subscriptions = new Set<string>()
    const listeners = new Set<OnMessageCallback>()
    function messageHandler (event: MessageEvent): void {
      try {
        debugLog(DEBUG_SCOPE, 'message received')
        if (event.data && typeof event.data === 'object' && 'topic' in event.data && 'payload' in event.data) {
          const topic: string = event.data.topic
          const payload: string = JSON.stringify(event.data.payload as AnyObject)
          debugLog(DEBUG_SCOPE, `Received postMessage with topic ${topic} and payload ${payload}`)
          for (const listener of listeners) {
            // @ts-expect-error: string is a valid payload type
            listener(topic, payload, {} as mqtt.IPublishPacket)
          }
          debugLog(DEBUG_SCOPE, `Sent to listeners: ${listeners.size}`)
        } else {
          debugLog(DEBUG_SCOPE, `Skipping postMessage with incorrect format: ${event.data}`)
        }
      } catch (err) {
        debugLog(DEBUG_SCOPE, 'Event Error: ' + JSON.stringify(err as AnyObject))
      }
    }
    /* istanbul ignore next: only used for preview @preserve */
    window.addEventListener(
      'message',
      messageHandler,
      false,
    )
    return {
      subscribe (topic) {
        subscriptions.add(topic as string)
        return this as MqttClient
      },
      unsubscribe (topic) {
        subscriptions.delete(topic as string)
        return this as MqttClient
      },
      prependListener (_eventName, listener) {
        listeners.add(listener as OnMessageCallback)
        return this as MqttClient
      },
      removeListener (_eventName, listener) {
        listeners.delete(listener as OnMessageCallback)
        return this as MqttClient
      },
      // @ts-expect-error: only really care about one type of listener
      listeners () {
        return [...listeners]
      },
      // @ts-expect-error: not all variants are implemented
      publish (topic, message, _options, callback) {
        debugLog(DEBUG_SCOPE, `Publishing ${topic} to parent window (preview mode)`)
        debugLog(DEBUG_SCOPE, message as string)
        const data = {
          topic,
          payload: JSON.parse(message as string),
        }
        if (import.meta.env.MODE === 'test') {
          messageHandler({ data } as MessageEvent)
        } else {
          window.parent.postMessage(data, '*')
        }
        if (callback) {
          callback()
        }
        return this as MqttClient
      },
    }
  }

  const client: MinimumMqttClient = (() => {
    /* istanbul ignore else: only use fake in tests @preserve */
    if (dataStore.settings.previewMode || import.meta.env.MODE === 'test') {
      connectionStatusInternal.value = 'Preview Mode'
      return createFakeClient()
    } else {
      return mqtt.connect({
        protocol: 'wss',
        hostname: window.location.hostname,
        port: dataStore.settings.mqtt.brokerWsPort,
        username: dataStore.settings.mqtt.username ?? '',
        password: dataStore.settings.mqtt.password ?? '',
        path: '/mqtt',
      })
        .on('connect', () => {
          connectionStatusInternal.value = 'Connected'
        })
        .on('close', () => {
          connectionStatusInternal.value = 'Closed'
        })
        .on('offline', () => {
          connectionStatusInternal.value = 'Offline'
        })
        .on('disconnect', () => {
          connectionStatusInternal.value = 'Disconnected'
        })
        .on('reconnect', () => {
          connectionStatusInternal.value = 'Reconnecting...'
        })
        .on('error', (err) => {
          connectionStatusInternal.value = 'Failed'
          console.log(err)
        })
    }
  })()

  const subscribe: MqttStoreReturnValue['subscribe'] = (topic, cb, options) => {
    debugLog(DEBUG_SCOPE, `Subscribing to topic ${topic}`)
    if (!subscriptionCounts[topic]) subscriptionCounts[topic] = 0
    client.subscribe(topic, { qos: 1, ...options })
    subscriptionCounts[topic]++
    const messageCallback: OnMessageCallback = (messageTopic, payload) => {
      if (messageTopic !== topic) return
      void cb(JSON.parse(payload.toString()))
    }
    client.prependListener('message', messageCallback)
    debugLog(DEBUG_SCOPE, `Added Message Listener for ${topic}, count: ${client.listeners('message').length}`)
    return () => {
      // unsubscribe if the last subscription
      if (--subscriptionCounts[topic] <= 0) {
        client.unsubscribe(topic)
        debugLog(DEBUG_SCOPE, `Unsubscribing from topic ${topic}`)
      }
      client.removeListener('message', messageCallback)
      debugLog(DEBUG_SCOPE, `Removed Message Listener for ${topic}, count: ${client.listeners('message').length}`)
    }
  }

  const publish: MqttStoreReturnValue['publish'] = (topic, message, options, callback) => {
    client.publish(topic, JSON.stringify(message), { qos: 1, ...options }, callback)
  }

  const connectionStatus: MqttStoreReturnValue['connectionStatus'] = computed(() => connectionStatusInternal.value)

  const internalPublish: MqttStoreReturnValue['internalPublish'] = (topic, message) => {
    client.listeners('message').forEach((listener) => {
      // @ts-expect-error: string is a valid payload type
      listener(topic, JSON.stringify(message), {} as mqtt.IPublishPacket)
    })
  }

  return {
    connectionStatus,
    subscribe,
    publish,
    internalPublish,
  }
})
