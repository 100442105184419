/**
 * The offset applied to "Alert" zone types.
 */
export const ALERT_ID_OFFSET = 10_000
/**
 * The offset applied to "Display" zone types.
 */
export const DISPLAY_ID_OFFSET = 20_000
/**
 * The offset applied to "Tour" zone types.
 */
export const TOUR_ID_OFFSET = 30_000
