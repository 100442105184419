/**
 * Returns a redacted version of the email address.
 */
export function redactEmail (email: string | undefined): string {
  if (!email) return ''
  return email.replace(/^.*@/, (r) => {
    return r.slice(0, 1) +
      r.slice(1, r.length - 2).replace(/./g, '*') +
      r.slice(r.length - 2)
  })
}
