/* istanbul ignore file @preserve */
import Display from '@/pages/Display.vue'
import Exhibit from '@/pages/Exhibit.vue'
import Home from '@/pages/Home.vue'
import Network from '@/pages/Network.vue'
import NotFound from '@/pages/NotFound.vue'
import Tour from '@/pages/Tour.vue'
import Welcome from '@/pages/Welcome.vue'

import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  CALIBRATE: 'calibrate',
  EXHIBIT: 'exhibit',
  TOUR: 'tour',
  DISPLAY: 'display',
  NETWORK: 'network',
  HOME: 'home',
  NOT_FOUND: '404',
  WELCOME: 'welcome',
}

export const routes: RouteRecordRaw[] = [
  {
    name: routeNames.HOME,
    path: '/',
    component: Home,
  },
  {
    name: routeNames.WELCOME,
    path: '/welcome',
    component: Welcome,
  },
  {
    name: routeNames.EXHIBIT,
    path: '/exhibit/:id',
    component: Exhibit,
  },
  {
    name: routeNames.TOUR,
    path: '/tour/:id',
    component: Tour,
  },
  {
    name: routeNames.DISPLAY,
    path: '/display/:id',
    component: Display,
  },
  {
    name: routeNames.NETWORK,
    path: '/network',
    component: Network,
  },
  {
    name: routeNames.NOT_FOUND,
    path: '/404',
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: routeNames.NOT_FOUND },
  },
]
