/* istanbul ignore file @preserve */
import { createRouter, createWebHashHistory } from 'vue-router'

import { routes } from '@/router'

export const router = createRouter({
  // so can be mounted in iframe for preview
  history: createWebHashHistory(),
  scrollBehavior () {
    return { top: 0, left: 0, behavior: 'smooth' }
  },
  routes,
})
