<template>
  <button
    class="btn"
    :class="{ sent, error: sendState === 'error' }"
    :aria-label="sent ? 'Souvenir Sent' : 'Send Souvenir'"
    @click="onSend"
  >
    <FontAwesomeIcon v-if="sent" icon="check" size="lg" />
    <FontAwesomeIcon v-else-if="sendState === 'error'" icon="arrow-rotate-right" size="lg" />
    <span v-else v-text="sendText" />
  </button>
  <Teleport to="body">
    <div
      class="modal-overlay"
      :class="{ 'modal-overlay-visible': modalVisible }"
    />
    <div
      class="modal-card flex-center text-center"
      :class="{ 'modal-card-visible': modalVisible }"
    >
      <DataForm email-only @continue="onContinue" />
    </div>
  </Teleport>
</template>

<script lang="ts">
import { DeepReadonly, PropType, computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import DataForm from './DataForm.vue'
import { useLocalStorageStore } from '@/stores/local-storage'
import { useMqttStore } from '@/stores/mqtt'

import { Exhibit } from 'types/data'
import { EmailMessagePayload } from 'types/mqtt-messages'

export default defineComponent({
  components: {
    DataForm,
  },

  props: {
    exhibit: { type: Object as PropType<DeepReadonly<Exhibit>>, required: true },
  },

  setup (props) {
    const { t } = useI18n()
    const mqttStore = useMqttStore()
    const localStorageStore = useLocalStorageStore()

    const souvenirId = computed(() => props.exhibit.souvenirId as number)

    const historyItem = computed(() => localStorageStore.souvenirHistory.getItem(souvenirId.value))

    const email = computed(() => {
      return localStorageStore.getUserData()?.email
    })

    const modalVisible = ref(false)
    const sent = computed(() => historyItem.value.sent)
    const sendState = ref<'idle' | 'sending' | 'error'>('idle')
    const sendText = computed(() => {
      if (sent.value) return ''
      if (sendState.value === 'sending') return '. . .'
      return t('claimYourSouvenir')
    })
    function sendEmail (): void {
      const userData = localStorageStore.getUserData()
      if (!userData) return
      sendState.value = 'sending'
      mqttStore.publish<EmailMessagePayload>(
        'email',
        {
          email: userData.email,
          languageCode: userData.language,
          souvenirId: souvenirId.value,
        },
        { qos: 2 },
        (err) => {
          if (err) {
            console.error(err)
            sendState.value = 'error'
            return
          }
          sendState.value = 'idle'
          localStorageStore.souvenirHistory.setItem(souvenirId.value, { sent: true })
        },
      )
    }
    function onSend (): void {
      if (sent.value) return
      if (!email.value) {
        modalVisible.value = true
        return
      }
      sendEmail()
    }
    function onContinue (): void {
      modalVisible.value = false
      if (email.value) {
        sendEmail()
      }
    }

    return {
      email,
      modalVisible,
      sent,
      sendState,
      sendText,
      onSend,
      onContinue,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-card {
  padding: 0 36px 40px;
}
button {
  margin: auto auto 24px;
  width: 300px;
  &.sent {
    background-color: var(--success);
  }
  &.error {
    background-color: var(--error);
  }
}
:deep(.field) {
  margin-bottom: 80px;
}
</style>
