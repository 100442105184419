<template>
  <button
    class="btn"
    :class="[exhibitType]"
    @click="onNavigate"
  >
    <p v-text="exhibitName" />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { useLocalStorageStore } from '@/stores/local-storage'
import { useMqttStore } from '@/stores/mqtt'

import type { Exhibit } from 'types/data'
import type { LocationMessagePayload } from 'types/mqtt-messages'
import type { DeepReadonly, PropType } from 'vue'

export default defineComponent({
  props: {
    exhibit: { type: Object as PropType<DeepReadonly<Exhibit>>, required: true },
  },

  setup (props) {
    const localStorageStore = useLocalStorageStore()
    const mqttStore = useMqttStore()

    const exhibitType = computed(() => (props.exhibit.zoneType ?? '').toLowerCase())
    const exhibitName = computed(() => props.exhibit.name ? props.exhibit.name : '[???]')

    function onNavigate (): void {
      const tag = localStorageStore.getUserData()?.tag
      const message: LocationMessagePayload = {
        exhibitId: -1,
        alertId: -1,
        tourId: -1,
        displayId: -1,
      }
      switch (props.exhibit.zoneType) {
        case 'Alert': message.alertId = props.exhibit.id; break
        case 'Tour': message.tourId = props.exhibit.id; break
        case 'Display': message.displayId = props.exhibit.id; break
        default: message.exhibitId = props.exhibit.id; break
      }
      mqttStore.publish(`location/${tag}`, message)
    }

    return {
      onNavigate,
      exhibitType,
      exhibitName,
    }
  },
})
</script>

<style lang="scss" scoped>
button {
  margin-bottom: 16px;
  &.alert {
    background-color: var(--error);
  }
  &.tour {
    background-color: var(--text);
  }
  &.display {
    background-color: var(--success);
  }
}
</style>
