<template>
  <header>
    <nav class="flex-center" :class="{ home: routerStore.home }">
      <button
        v-if="showBack"
        class="icon-btn back-btn"
        aria-label="Back"
        @click="onBack"
      >
        <FontAwesomeIcon icon="fa-arrow-left" size="xl" />
      </button>
      <span class="nav-menu-title" v-text="routerStore.title" />
      <button class="icon-btn settings-btn" aria-label="Settings" @click="showSettings()">
        <FontAwesomeIcon icon="fa-gear" size="xl" />
      </button>
    </nav>
    <SettingsCard />
  </header>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import SettingsCard from './SettingsCard.vue'
import { useDataStore } from '@/stores/data'
import { useExperienceStore } from '@/stores/experience'
import { useModalStore } from '@/stores/modal'
import { useRouterStore } from '@/stores/router'

import { routeNames } from '@/router'

export default defineComponent({
  components: {
    SettingsCard,
  },

  setup () {
    const { t } = useI18n()
    const routerStore = useRouterStore()

    const dataStore = useDataStore()
    const showBack = computed(() => {
      return dataStore.museumType === 'traditional' && !routerStore.home
    })

    const router = useRouter()
    const experienceStore = useExperienceStore()
    async function onBack (): Promise<void> {
      experienceStore.deactivate()
      // cannot use `back` as Blu phones do not support browser history
      await router.push({ name: routeNames.HOME })
    }

    const modalStore = useModalStore()
    function showSettings (): void {
      modalStore.settings = true
    }

    return {
      t,
      routerStore,
      showBack,
      onBack,
      showSettings,
    }
  },
})
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
  width: 100%;
  z-index: 1;
}
nav {
  background-color: var(--secondary);
  color: var(--primary);
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 0 64px;
  box-shadow: var(--nav-shadow);

  .nav-menu-title {
    font-size: 16px;
    letter-spacing: 1.92px;
    text-align: center;
    font-family: 'JW Knoll Bold';
    text-transform: uppercase;
  }

  &.home {
    background-color: var(--primary);
    color: var(--white);

    .settings-btn {
      color: var(--white);
    }
  }
}

.icon-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 8px;
  top: 16px;
}

.back-btn {
  left: 16px;
}

.settings-btn {
  right: 16px;
  background: transparent;
  color: var(--primary);
}
</style>
