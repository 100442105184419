/* istanbul ignore file @preserve */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faArrowRotateRight,
  faChartSimple,
  faCheck,
  faChevronRight,
  faCircleArrowRight,
  faCircleInfo,
  faCircleQuestion,
  faForwardStep,
  faGear,
  faHeadphones,
  faPause,
  faPersonWalkingArrowRight,
  faPlay,
  faRotateLeft,
  faSpinner,
  faVolumeHigh,
  faVolumeXmark,
  faWarning,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

library.add(faGear)
library.add(faCircleQuestion)
library.add(faPersonWalkingArrowRight)
library.add(faPause)
library.add(faHeadphones)
library.add(faPlay)
library.add(faChartSimple)
library.add(faChevronRight)
library.add(faArrowLeft)
library.add(faWarning)
library.add(faXmark)
library.add(faCheck)
library.add(faCircleInfo)
library.add(faRotateLeft)
library.add(faForwardStep)
library.add(faCircleArrowRight)
library.add(faArrowRotateRight)
library.add(faVolumeHigh)
library.add(faVolumeXmark)
library.add(faSpinner)

export { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
