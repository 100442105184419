// These are mirrored in styles/variables.scss
export const cssVariables = {
  primary: '#4294A6',
  secondary: '#C2DBE0',
  text: '#414141',
  success: '#62A642',
  error: '#FF0000',
  lightgray: '#CCCCCC',
  gray: '#BEBEBE',
  midgray: '#E1E1E1',
  darkgray: '#333333',
  fadedgray: '#707070',
  white: '#FFFFFF',
  black: '#000000',
  navShadow: '#A2A2A229 0 3px 6px',
  trackShadow: '#81818154 0 3px 6px',
  modalGradient: 'linear-gradient(0deg, #393838, #0D0D0D)',
  pagePadding: '12px',
  navHeight: '70px',
  zDisplayConnect: '40',
  zModalOverlay: '50',
  zModalSettingsOverlay: '51',
  zModalPlayerCard: '99',
  zModalCard: '100',
  zDisplayBanner: '100',
  zModalAlertCard: '150',
  zModalSettingsCard: '151',
}
