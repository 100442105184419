/* istanbul ignore file @preserve */
import { createI18n } from 'vue-i18n'

import { useDataStore } from '@/stores/data'

import type { LanguageStrings } from 'types/data'
import type { NoNullFields } from 'types/utils'
import type { I18n } from 'vue-i18n'

type Locale = 'en' | 'es'

export function createI18NPlugin (): I18n {
  const dataStore = useDataStore()
  const messages = dataStore.languageStrings.reduce((acc, lang) => {
    if (lang?.symbol) {
      acc[lang.symbol as Locale] = lang as NoNullFields<LanguageStrings>
    }
    return acc
  }, {} as Record<Locale, NoNullFields<LanguageStrings>>)

  return createI18n<false>({
    legacy: false,
    globalInjection: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    postTranslation: (message) => {
      if (typeof message !== 'string') return message
      return message.trim()
    },
  })
}
