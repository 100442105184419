<template>
  <div class="track-selector flex-column flex-center">
    <template v-if="showSearch">
      <FontAwesomeIcon icon="fa-headphones" size="3x" />
      <div class="field">
        <label v-text="t('enterNumber')" />
        <input
          ref="searchRef"
          v-model="trackNumber"
          type="number"
          min="0"
          max="9999"
          autocomplete="off"
          @keyup="onKeyup"
          @keyup.enter="onSearch"
        >
      </div>
      <p
        v-if="showError"
        class="text-error search-error"
        v-text="t('invalidNumber')"
      />
      <button
        class="btn search-btn"
        :disabled="buttonDisabled"
        @click="onSearch"
        v-text="t('search')"
      />
    </template>
    <template v-else>
      <div class="track-grid">
        <div
          v-for="button of gridButtons"
          :key="button.text"
          class="track-grid--box"
        >
          <button
            :class="{ missing: button.missing, active: button.active, played: !button.active && button.played }"
            @click="selectTrack($event, button.track)"
          >
            <FontAwesomeIcon
              v-if="button.playing"
              icon="fa-chart-simple"
              size="2x"
            />
            <span v-else v-text="button.text" />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useExperienceStore } from '@/stores/experience'

import type { TourPositionMedia, TourCard } from 'types/data'
import type { DeepReadonly, PropType } from 'vue'

interface GridButton {
  text: string
  missing: boolean
  track: TourPositionMedia | null
}

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },

  emits: {
    select: (ev: MouseEvent | null, track: TourPositionMedia | null): true => true,
  },

  props: {
    tour: { type: Object as PropType<DeepReadonly<TourCard>>, required: true },
  },

  setup (props, { emit }) {
    const { t } = useI18n()

    const experienceStore = useExperienceStore()

    const showSearch = computed(() => {
      return !props.tour.showGridView
    })

    // grid
    const gridButtonDetails = computed(() => {
      const buttons: GridButton[] = []
      if (!props.tour.positions) return buttons
      props.tour.positions.forEach((pos, i, arr) => {
        if (i > 0 && props.tour.showMissingTracks) {
          const prev = arr[i - 1].number
          const curr = pos.number
          if (curr - prev === 2) {
            buttons.push({
              missing: true,
              text: String(curr - 1),
              track: null,
            })
          } else if (curr - prev > 2) {
            buttons.push({
              missing: true,
              text: `${prev + 1}-${curr - 1}`,
              track: null,
            })
          }
        }
        buttons.push({
          missing: false,
          text: String(pos.number),
          track: pos,
        })
      })
      return buttons
    })

    function isActiveTrack (track: TourPositionMedia | null): boolean {
      if (!track || !experienceStore.activeExperience) return false
      return experienceStore.getId(track) === experienceStore.activeExperience.id
    }
    const gridButtons = computed(() => {
      return gridButtonDetails.value.map((button) => {
        const active = isActiveTrack(button.track)
        const playing = active && experienceStore.activeExperience?.playState.state === 'playing'
        let played = false
        if (button.track) {
          const playState = experienceStore.getPlayState(button.track)
          played = playState.state === 'played'
        }
        return {
          ...button,
          active,
          playing,
          played,
        }
      })
    })

    // search
    const trackNumber = ref('')
    const searchRef = ref<HTMLInputElement | null>(null)

    const showError = ref(false)
    function onKeyup (): void {
      showError.value = false
      experienceStore.deactivate()
      experienceStore.activeExperience = null
    }
    watch(() => experienceStore.activeExperience, (val) => {
      if (val) {
        const experience = val.experience
        if ('number' in experience) {
          trackNumber.value = experience.number.toString()
        }
      }
    })

    function selectTrack (ev: MouseEvent | null, track: TourPositionMedia | null): void {
      emit('select', ev, track)
    }
    function onSearch (): void {
      if (buttonDisabled.value) return
      const matchedTrack = props.tour.positions?.find((e) => e.number === +trackNumber.value)
      if (!matchedTrack) {
        showError.value = true
      } else {
        selectTrack(null, matchedTrack)
        if (searchRef.value) {
          searchRef.value.blur()
        }
      }
    }

    const buttonDisabled = computed(() => {
      return !trackNumber.value || showError.value || !!experienceStore.activeExperience
    })

    return {
      t,
      showSearch,
      // grid
      selectTrack,
      gridButtons,
      // search
      trackNumber,
      searchRef,
      showError,
      onKeyup,
      onSearch,
      buttonDisabled,
    }
  },
})
</script>

<style lang="scss" scoped>
.track-selector {
  color: var(--primary);
  padding-bottom: 16px;
  margin-bottom: 150px;

  .field,
  p {
    margin-bottom: 16px;
  }

  .search-error,
  .search-btn {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.track-grid {
  width: 100%;
  gap: 14px;
  margin-top: 20px;
  margin-bottom: 100px;
  display: flex;
  flex-flow: row wrap;
}
@supports (display: grid) {
  .track-grid {
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 64px);
    grid-gap: 14px;
    justify-content: center;
  }
}

.track-grid--box {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    font-family: 'JW Knoll Display';
    background-color: var(--lightgray);
    color: var(--text);
    height: 64px;
    width: 64px;
    font-size: 16px;
    letter-spacing: 1.92px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    user-select: none;
    position: relative;
    border-radius: 4px;
    box-shadow: var(--track-shadow);
    &.missing {
      opacity: 0.5;
    }
    &.active {
      color: var(--white);
      background-color: var(--primary);
    }
    &.played {
      color: var(--white);
      background-color: var(--primary);
      opacity: 0.7;
    }
  }
}
</style>
