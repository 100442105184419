/* istanbul ignore file @preserve */
import { Fragment, createApp, h } from 'vue'

import '@/styles/index.scss'
import App from '@/App.vue'
import { useDataStore } from '@/stores/data'

import { FontAwesomeIcon } from '@/plugins/font-awesome'
import { createI18NPlugin } from '@/plugins/i18n'
import { pinia } from '@/plugins/pinia'
import { router } from '@/plugins/router'
import { installSentry } from '@/plugins/sentry'

void (async function create (): Promise<void> {
  const app = await (async () => {
    // Remove `&& false` to enable vue-axe
    // eslint-disable-next-line no-constant-condition
    if (import.meta.env.DEV && false) {
      // @ts-expect-error
      const VueAxe = await import('vue-axe')
      return createApp({
        render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)]),
      }).use(VueAxe.default)
    } else {
      return createApp(App)
    }
  })()
  if (import.meta.env.PROD) {
    installSentry(app, router)
  }
  app.use(pinia)
  app.use(router)
  const dataStore = useDataStore()
  const dataLoadSuccess = await dataStore.loadData()
  if (!dataLoadSuccess) return
  const i18n = createI18NPlugin()
  app.use(i18n)
  app.component('FontAwesomeIcon', FontAwesomeIcon)
  app.mount('#app')
})()
