<template>
  <div id="experience-list" class="flex-column">
    <div class="experiences">
      <ExperienceListItem
        v-for="experience of experiences"
        :key="experience.id"
        :experience="experience"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'

import ExperienceListItem from './ExperienceListItem.vue'
import { useExperienceStore } from '@/stores/experience'

import type { Experience } from 'types/data'
import type { DeepReadonly, PropType } from 'vue'

export default defineComponent({
  components: {
    ExperienceListItem,
  },

  props: {
    experiences: { type: Array as PropType<DeepReadonly<Experience[]>>, required: true },
  },

  setup (props) {
    const experienceStore = useExperienceStore()
    watch(() => props.experiences, (curr, prev) => {
      if (curr !== prev) {
        experienceStore.deactivate()
      }
    }, { immediate: true })

    return {}
  },
})
</script>

<style lang="scss" scoped>
.experiences {
  border-top: 1px solid var(--lightgray);
  width: 100vw;
  padding: 0 20px;
  margin: 0 calc(var(--page-padding) * -1);
}
</style>
