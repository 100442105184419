<template>
  <div class="page-container flex-column">
    <HomeLocation v-if="museumType === 'location'" />
    <TourList v-else />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import HomeLocation from '@/components/HomeLocation.vue'
import TourList from '@/components/TourList.vue'
import { useDataStore } from '@/stores/data'

export default defineComponent({
  components: {
    HomeLocation,
    TourList,
  },

  setup () {
    const dataStore = useDataStore()

    const museumType = computed(() => dataStore.museumType)

    return {
      museumType,
    }
  },
})
</script>
