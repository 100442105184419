/**
 * Checks to see if the playback error is a user or UI issue and not a genuine error.
 *
 * @returns {boolean} - True if the error can be ignored, false otherwise.
 */
export function checkPlaybackError (err: unknown): boolean {
  if (err instanceof Error) {
    // ignore navigation before loading
    if (err.name === 'AbortError') {
      if (err.message.includes('call to pause')) return true
      if (err.message.includes('pause was requested')) return true
      if (err.message.includes('media was removed')) return true
      if (err.message.includes('operation was aborted')) return true
      if (err.message.includes('paused to save power')) return true
    }
    // ignore if user has not interacted with the page yet
    if (err.name === 'NotAllowedError') return true
    // sometimes occurs if connection is lost or user interaction is needed
    if (err.name === 'NotSupportedError') return true
  }
  return false
}

/**
 * Logs to the console with a green color.
 * Does nothing during tests.
 *
 * @param scope The scope of the log message, such as LocationWatcher.
 * @param message The message to log.
 */
/* istanbul ignore next @preserve */
export function debugLog (scope: string, message: string): void {
  if (import.meta.env.MODE === 'test') return
  // if (import.meta.env.PROD) return
  console.log(`%c[${scope}] ${message}`, 'color: green;')
}
