import { defineStore } from 'pinia'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useExperienceStore } from '@/stores/experience'

import { useExhibitStore } from './exhibit'
import { routeNames } from '@/router'
import { TOUR_ID_OFFSET } from '@/utils/constants'

import type { Exhibit, TourCard } from 'types/data'
import type { ComputedRef, DeepReadonly } from 'vue'

interface RouterReturnValue {
  path: ComputedRef<string>
  pathWithoutParams: ComputedRef<string>
  home: ComputedRef<boolean>
  onSplash: ComputedRef<boolean>
  title: ComputedRef<string>
  exhibit: ComputedRef<DeepReadonly<Exhibit> | null>
  tour: ComputedRef<DeepReadonly<TourCard> | null>
}

export const useRouterStore = defineStore('router', (): RouterReturnValue => {
  const route = useRoute()

  const path: RouterReturnValue['path'] = computed(() => route.path)
  const pathWithoutParams: RouterReturnValue['pathWithoutParams'] = computed(() => route.path.split('?')[0])
  const home: RouterReturnValue['home'] = computed(() => route.name === routeNames.HOME)
  const onSplash: RouterReturnValue['onSplash'] = computed(() => {
    return [routeNames.WELCOME].includes(route.name as string)
  })

  const { t } = useI18n()
  const title: RouterReturnValue['title'] = computed(() => {
    return exhibit.value?.title || t('branchName')
  })

  const experienceStore = useExperienceStore()
  watch(pathWithoutParams, () => {
    experienceStore.deactivate()
  })

  const exhibitStore = useExhibitStore()
  const exhibit: RouterReturnValue['exhibit'] = computed(() => {
    const offset = (() => {
      switch (route.name) {
        case routeNames.EXHIBIT:
        case routeNames.DISPLAY:
          return 0
        case routeNames.TOUR:
          return TOUR_ID_OFFSET
        default:
          return -1
      }
    })()
    if (offset === -1) return null
    return exhibitStore.getExhibitById(String(+route.params.id + offset)) ?? null
  })
  const tour: RouterReturnValue['tour'] = computed(() => {
    if (route.name === routeNames.TOUR) {
      return exhibitStore.getCardById(String(route.params.id)) ?? null
    }
    return null
  })

  return {
    path,
    pathWithoutParams,
    home,
    onSplash,
    title,
    exhibit,
    tour,
  }
})
