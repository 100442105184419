<template>
  <h1 class="text-center" v-text="t('companionAppName')" />
  <p class="text-center" v-text="t('asYouMove')" />
  <div class="no-media flex-column flex-center">
    <FontAwesomeIcon icon="fa-person-walking-arrow-right" size="4x" />
    <p v-text="t('noMediaTracks')" />
  </div>
  <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
  <div v-if="demoStore.isDemo" class="demo-section demo-links flex-center flex-column">
    <p class="text-center">
      (Demo links below)
    </p>
    <ExhibitLink v-for="exhibit of exhibitStore.exhibits" :key="exhibit.id" :exhibit="exhibit" />
  </div>
  <!-- eslint-enable -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import ExhibitLink from '@/components/ExhibitLink.vue'
import { useDemoStore } from '@/stores/demo'
import { useExhibitStore } from '@/stores/exhibit'

export default defineComponent({
  components: {
    ExhibitLink,
  },

  setup () {
    const { t } = useI18n()
    const demoStore = useDemoStore()
    const exhibitStore = useExhibitStore()

    return {
      t,
      demoStore,
      exhibitStore,
    }
  },
})
</script>

<style lang="scss" scoped>
h1 {
  font-family: 'JW Knoll Display';
  font-size: 26px;
  font-weight: bold;
  margin: 60px 0 20px;
}

p {
  font-weight: 500;
  font-size: 14px;
}

.no-media {
  margin-top: 110px;
  color: var(--gray);

  p {
    margin: 20px 0 60px;
  }
}

.demo-links {
  margin: auto 0 40px;
  width: 100%;

  p {
    margin-bottom: 12px;
  }
}
</style>
