<template>
  <div v-if="experienceDetails" class="experience flex-center" @click="activate">
    <div class="active-icon flex-center flex-column">
      <FontAwesomeIcon :icon="experienceDetails.icon" size="sm" />
    </div>
    <p :style="{ color: experienceDetails.titleColor }" v-html="experience.name" />
    <span :style="{ color: experienceDetails.timeColor }" class="played">
      {{ experienceDetails.timeText }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { useExperienceStore } from '@/stores/experience'

import { cssVariables } from '@/utils/css'
import { secondsToMinutes } from '@/utils/time'

import type { ActiveExperience } from '@/stores/experience'
import type { Experience } from 'types/data'
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    experience: { type: Object as PropType<Experience>, required: true },
  },

  setup (props) {
    const experienceStore = useExperienceStore()
    const experienceDetails = computed(() => {
      const active = experienceStore.getId(props.experience) === experienceStore.activeExperience?.id
      const playState = active
        ? (experienceStore.activeExperience as ActiveExperience).playState
        : experienceStore.getPlayState(props.experience)

      const icon = playState.state === 'playing' ? 'fa-chart-simple' : 'fa-play'
      const titleColor = !active && playState.state === 'played' ? cssVariables.lightgray : cssVariables.text
      const timeColor = playState.state === 'playing' ? cssVariables.primary : cssVariables.lightgray
      const timeText = playState.state === 'unplayed'
        ? `${secondsToMinutes(playState.totalTime)} min`
        : playState.state === 'playing'
          ? `${secondsToMinutes(Math.max(Math.ceil(playState.totalTime - playState.currentTime), 0))} left`
          : 'Played'

      return {
        icon,
        titleColor,
        timeColor,
        timeText,
      }
    })

    function activate (): void {
      experienceStore.activate(props.experience)
      void experienceStore.playPause()
    }

    return {
      experienceDetails,
      activate,
    }
  },
})
</script>

<style lang="scss" scoped>
.experience {
  border-bottom: 1px solid var(--lightgray);
  padding: 16px 0;

  .active-icon {
    color: var(--primary);
    margin-right: 6px;
    width: 14px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    margin-right: auto;
  }

  .played {
    font-family: 'JW Knoll Demi';
    color: var(--lightgray);
    font-size: 12px;
  }
}
</style>
