<template>
  <div class="page-container flex-column">
    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <h4>404 - {{ t('pageNotFound') }}</h4>
    <button class="btn" @click="goHome" v-text="t('returnHome')" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { routeNames } from '@/router'

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const router = useRouter()

    async function goHome (): Promise<void> {
      await router.push({ name: routeNames.HOME })
    }
    return {
      t,
      goHome,
    }
  },
})
</script>
