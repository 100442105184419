import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useMqttStore } from '@/stores/mqtt'

import { useDataStore } from './data'
import { useLocalStorageStore } from './local-storage'
import { checkPlaybackError } from '@/utils/error'

import type { PlayHistoryItem } from './local-storage'
import type { TourPositionMedia, Experience, MediaAsset } from 'types/data'
import type { VideoMessagePayload } from 'types/mqtt-messages'
import type { Ref } from 'vue'

export interface PlayState {
  state: 'unplayed' | 'playing' | 'paused' | 'played'
  totalTime: number
  currentTime: number
  canplay: boolean
}

/**
 * The type of media asset that is currently active.
 * - `audio`: An audio file.
 * - `video`: A synced video file.
 * - `inlineVideo`: A video file that is played within the app.
 */
type MediaType = 'audio' | 'video' | 'inlineVideo'
export type ActiveExperience = Readonly<{
  type: MediaType
  id: string
  src: string
  experience: Experience | TourPositionMedia
  playState: PlayState
  playHistory: PlayHistoryItem
  audio: HTMLAudioElement | null
}>

function getMediaType (media: MediaAsset | null, inlineVideo?: boolean): MediaType {
  switch (media?.type) {
    case 'mp4':
    case 'w4v':
    case 'webm':
    case 'mov':
      return inlineVideo ? 'inlineVideo' : 'video'
    default:
      return 'audio'
  }
}

interface ExperienceStoreReturnValue {
  activeExperience: Ref<ActiveExperience | null>
  subscription: Ref<(() => void) | null>
  getId: (experience: Experience | TourPositionMedia) => string
  deactivate: () => void
  activate: (experience: Experience | TourPositionMedia, inlineVideo?: boolean) => void
  getPlayState: (experience: Experience | TourPositionMedia) => PlayState
  syncPlayHistory: () => void
  playPause: () => Promise<void>
  updateCurrentTime: (currentTime: number) => void
  updatePlayState: (playState: Partial<PlayState>) => void
  bindMediaEvents: (media: HTMLMediaElement) => void
}

export const useExperienceStore = defineStore('experience', (): ExperienceStoreReturnValue => {
  const localStorageStore = useLocalStorageStore()
  const dataStore = useDataStore()

  const activeExperience: ExperienceStoreReturnValue['activeExperience'] = ref(null)
  const subscription: ExperienceStoreReturnValue['subscription'] = ref(null)

  const getId: ExperienceStoreReturnValue['getId'] = (experience) => {
    return 'number' in experience ? `${experience.number}-${experience.media?.id}` : String(experience.id)
  }

  const deactivate: ExperienceStoreReturnValue['deactivate'] = () => {
    if (!activeExperience.value) return
    const active = activeExperience.value
    if (active.playState.state === 'playing') void playPause()
    if (active.audio) {
      active.audio.ontimeupdate = null
      active.audio.src = ''
    }
    if (subscription.value) {
      subscription.value()
      subscription.value = null
    }
    activeExperience.value = null
  }

  const activate: ExperienceStoreReturnValue['activate'] = (experience, inlineVideo) => {
    deactivate()

    const type = getMediaType(experience.media, inlineVideo)
    const id = getId(experience)
    const src = dataStore.getMediaSrc(experience.media)
    activeExperience.value = {
      id,
      type,
      src,
      experience,
      playState: getPlayState(experience),
      playHistory: localStorageStore.playHistory.getItem(id),
      audio: type === 'audio' ? createAudio(src) : null,
    }

    if (type === 'video') {
      const mqttStore = useMqttStore()
      subscription.value = mqttStore.subscribe(`interactive/${id}`, (payload: VideoMessagePayload) => {
        updatePlayState({ currentTime: payload.currentTime })
      })
    }
  }

  const getPlayState: ExperienceStoreReturnValue['getPlayState'] = (experience) => {
    const playHistory = localStorageStore.playHistory.getItem(getId(experience))
    return {
      state: playHistory.played ? 'played' : 'unplayed',
      totalTime: experience.media?.length ?? 0,
      currentTime: 0,
      canplay: false,
    }
  }

  const updatePlayState: ExperienceStoreReturnValue['updatePlayState'] = (playState) => {
    if (!activeExperience.value) return
    const active = activeExperience.value
    activeExperience.value = {
      ...active,
      playState: {
        ...active.playState,
        ...playState,
      },
    }
  }

  const bindMediaEvents: ExperienceStoreReturnValue['bindMediaEvents'] = (media: HTMLMediaElement) => {
    media.oncanplay = () => {
      updatePlayState({ canplay: true })
    }
    media.onwaiting = () => {
      updatePlayState({ canplay: false })
    }
    media.ontimeupdate = () => {
      updatePlayState({ currentTime: media.currentTime })
    }
    media.onended = () => {
      updatePlayState({ state: 'played' })
      syncPlayHistory()
    }
  }

  function createAudio (src: string): HTMLAudioElement {
    const audio = new Audio(src)
    audio.load()
    bindMediaEvents(audio)
    return audio
  }

  const syncPlayHistory: ExperienceStoreReturnValue['syncPlayHistory'] = () => {
    if (!activeExperience.value) return
    const active = activeExperience.value
    localStorageStore.playHistory.setItem(active.id, { ...active.playHistory, played: true })
  }

  const playPause: ExperienceStoreReturnValue['playPause'] = async () => {
    if (!activeExperience.value) return
    const active = activeExperience.value
    switch (active.playState.state) {
      case 'playing':
        updatePlayState({ state: 'paused' })
        active.audio?.pause()
        localStorageStore.playHistory.setItem(active.id, { ...active.playHistory, played: true })
        break
      default:
        try {
          updatePlayState({ state: 'playing' })
          await active.audio?.play()
        } catch (err) {
          active.audio?.pause()
          if (checkPlaybackError(err)) return
          console.error(err)
        }
    }
  }

  const updateCurrentTime: ExperienceStoreReturnValue['updateCurrentTime'] = (currentTime) => {
    if (activeExperience.value?.audio) {
      activeExperience.value.audio.currentTime = currentTime
    }
  }

  return {
    activeExperience,
    subscription,
    getId,
    deactivate,
    activate,
    getPlayState,
    syncPlayHistory,
    playPause,
    updateCurrentTime,
    updatePlayState,
    bindMediaEvents,
  }
})
